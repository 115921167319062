require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require('jquery');
require('bootstrap');
require('@fortawesome/fontawesome-free/js/all');
require('aos');
require('./website/startup-script');
require('./website/custom');

document.addEventListener('turbolinks:load', () => {
  $('[data-toggle="tooltip"]').tooltip();
});
